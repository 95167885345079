<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenService.fertilizer_request_report_title') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <!-- fiscal year list -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required">
              <b-form-group
                  label-for="fiscal_year_id"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                  {{ $t('globalTrans.fiscal_year') }}
                  </template>
                  <b-form-select
                  plain
                  v-model="search.fiscal_year_id"
                  :options="fiscalYearList"
                  id="service_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- circular list -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Circular Name" vid="circular_id" rules="required">
              <b-form-group
                  label-for="circular_id"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                  {{ $t('teaGardenPduService.circular_name')}}
                  </template>
                  <b-form-select
                  plain
                  v-model="search.circular_id"
                  :options="circularList"
                  id="service_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </card>
    <b-row>
      <b-col md="12">
        <body-card>
              <!-- table section start -->
              <template v-slot:headerAction>
                <a href="javascript:" class="btn-add" @click="pdfExport">
                   {{ $t('globalTrans.export_pdf') }}
                </a>
              </template>
              <template v-slot:body>
                <b-overlay>
                  <template>
                    <div class="text-black mb-4">
                      <b-row>
                        <b-col md="8">
                          {{ $t('globalTrans.fiscal_year') }}: <strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchHeaderData.fiscal_year_en : searchHeaderData.fiscal_year_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="4">
                          {{ $t('teaGardenPduService.circular_name') }}: <strong>{{ search.circular_id  ?  ($i18n.locale === 'en' ? searchHeaderData.circular_title_en : searchHeaderData.circular_title_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                      </b-row>
                    </div>
                    <div>
                    </div>
                  </template>
                </b-overlay>
              </template>
        </body-card>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
    <!-- badc sale center wise details -->
    <slot v-for="(sale_center, index) in badc_data_arr">
    <body-card :key="'badc-' + index">
      <template v-slot:body>
          <b-row class="mb-5">
            <div class="d-flex justify-content-center">
              <h5 class="text-center">
                <p v-if="index === 0">{{ currentLocale === 'bn' ? getFiscalYearName(search.fiscal_year_id) + ' অর্থ বছরের বাগান ভিত্তিক টিএপি, এমওপি ও ডিএপি সারের  বরাদ্দ পত্র ' : 'Garden wise allotment letter of TSP, MOP and DAP for the fiscal year ' + getFiscalYearName(search.fiscal_year_id) }} </p>
                <p>{{ getSaleCenterInfo(sale_center.sales_center_id) }}</p>
              </h5>
            </div>
          </b-row>
          <table class="table table-sm table-bordered section-tree-view-table">
            <thead>
              <tr>
                <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
                <th class="text-center">{{$t('teaGardenConfig.garden_name')}}</th>
                <th class="text-center">{{$t('globalTrans.address')}}</th>
                <th class="text-center">{{$t('teaGardenService.tsp')}} {{$t('globalTrans.m_ton')}}</th>
                <th class="text-center">{{$t('teaGardenService.mop')}} {{$t('globalTrans.m_ton')}}</th>
                <th class="text-center">{{$t('teaGardenService.dap')}} {{$t('globalTrans.m_ton')}}</th>
              </tr>
            </thead>
              <tbody>
                <slot v-for="(company, companyIndex) in sale_center.company_info">
                  <tr v-for="(gardenItem, gardenIndex) in company.garden_info" :key="'company-info-' + companyIndex + 'garden-info-' + gardenIndex">
                    <td class="text-center">{{ $n(gardenIndex + 1 ) }}</td>
                    <td class="text-center">{{getGardenName(gardenItem.garden_id)}}</td>
                    <slot v-if="gardenIndex === 0">
                    <td class="text-center" :rowspan="company.garden_info.length">{{getCompanyInfo(company.company_id)}}</td>
                    </slot>
                    <td class="text-center" v-for="(fertilizer, fertilizerIndex) in gardenItem.fertilizer_info" :key="'company-info-' + companyIndex + 'garden-info-' + gardenIndex + 'fertilizer-info-'+ fertilizerIndex">{{ $n(fertilizer.verified_qty) }}</td>
                  </tr>
                </slot>
              </tbody>
            <tbody>
              <tr :key="'company-info-garden-info-totals-' + index">
                <td colspan="3" class="text-right">{{$t('globalTrans.total')}}</td>
                <td class="text-center" v-for="(total_info, totalInfoIndex) in sale_center.total_info" :key="'company-info-' + 'garden-info-' + totalInfoIndex + 'badc-fertilizer-info'">{{ $n(total_info.total_qty) }}</td>
              </tr>
            </tbody>
          </table>
      </template>
    </body-card>
    </slot>
    <!-- BADC sale center fertilizer total summery-->
    <slot v-for="(sale_center_parent, badcFertilizerIndex) in sales_center_arr" >
    <body-card :key="'center-total-info-' + badcFertilizerIndex" v-if="sale_center_parent.type === 1">
      <b-row class="my-3">
        <div class="d-flex justify-content-center">
          <h5 class="text-center">
            <p>{{ currentLocale === 'bn' ? getFiscalYearName(search.fiscal_year_id) + ' অর্থ বছরের বাগান ভিত্তিক টিএপি, এমওপি ও ডিএপি সারের  ক্রমপুঞ্জিত পরিমাণ ' : 'Garden wise cumulative quantity of TSP, MOP and DAP for the fiscal year ' + getFiscalYearName(search.fiscal_year_id) }} </p>
          </h5>
        </div>
      </b-row>
      <table class="table table-sm table-bordered section-tree-view-table">
        <thead>
          <tr>
            <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
            <th class="text-center">{{$t('teaGardenService.badcSaleCenterName')}}</th>
            <th class="text-center">{{$t('teaGardenService.tsp')}} {{$t('globalTrans.m_ton')}}</th>
            <th class="text-center">{{$t('teaGardenService.mop')}} {{$t('globalTrans.m_ton')}}</th>
            <th class="text-center">{{$t('teaGardenService.dap')}} {{$t('globalTrans.m_ton')}}</th>
          </tr>
        </thead>
          <tbody>
            <slot v-for="(sale_center, badcFertilizerIndex) in sale_center_parent.sales_center_info">
              <tr :key="'badc-total-info-' + badcFertilizerIndex">
                <td class="text-center">{{ $n(badcFertilizerIndex + 1) }}</td>
                <td class="text-center">{{getSaleCenterInfo(sale_center.sales_center_id)}}</td>
                <td class="text-center" v-for="(fertilizer, fertilizerIndex) in sale_center.fertilizer_info" :key="'badc-fertilizer-total-' + badcFertilizerIndex + '-' + fertilizerIndex">{{ $n(fertilizer.verified_qty) }}</td>
              </tr>
            </slot>
          </tbody>
        <tbody>
          <tr>
            <td colspan="2" class="text-right">{{$t('globalTrans.total')}}</td>
            <td v-for="(total_item, fertilizerTotalInfoIndex) in sale_center_parent.total_info" :key="'total-info-' +fertilizerTotalInfoIndex" class="text-center">{{$n(total_item.total_qty)}}</td>
          </tr>
        </tbody>
      </table>
    </body-card>
    </slot>
    <!-- bcic sale center wise details -->
    <slot v-for="(sale_center, index) in bcic_data_arr">
    <body-card :key="'bcic-'+ index">
      <template v-slot:body>
          <b-row class="mb-5">
            <div class="d-flex justify-content-center">
              <h5 class="text-center">
                <p v-if="index === 0">{{ currentLocale === 'bn' ? getFiscalYearName(search.fiscal_year_id) + ' অর্থ বছরের বাগান ভিক্তিক ইউরিয়া সারের বরাদ্দ পত্র ' : 'Garden wise allotment letter of Urea for the fiscal year ' + getFiscalYearName(search.fiscal_year_id) }} </p>
                <p>{{ getSaleCenterInfo(sale_center.sales_center_id) }}</p>
              </h5>
            </div>
          </b-row>
          <table class="table table-sm table-bordered section-tree-view-table">
            <thead>
              <tr>
                <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
                <th class="text-center">{{$t('teaGardenConfig.garden_name')}}</th>
                <th class="text-center">{{$t('globalTrans.address')}}</th>
                <th class="text-center">{{$t('teaGardenService.urea')}} {{$t('globalTrans.m_ton')}}</th>
              </tr>
            </thead>
              <tbody>
                <slot v-for="(company, companyIndex) in sale_center.company_info">
                  <tr v-for="(gardenItem, gardenIndex) in company.garden_info" :key="'company-info-' + companyIndex + 'garden-info-' + gardenIndex">
                    <td class="text-center">{{ $n(gardenIndex + 1 ) }}</td>
                    <td class="text-center">{{getGardenName(gardenItem.garden_id)}}</td>
                    <slot v-if="gardenIndex === 0">
                    <td class="text-center" :rowspan="company.garden_info.length">{{getCompanyInfo(company.company_id)}}</td>
                    </slot>
                    <td class="text-center" v-for="(fertilizer, fertilizerIndex) in gardenItem.fertilizer_info" :key="'company-info-' + companyIndex + 'garden-info-' + gardenIndex + 'fertilizer-info-'+ fertilizerIndex">{{ $n(fertilizer.verified_qty) }}</td>
                  </tr>
                </slot>
              </tbody>
            <tbody>
              <tr :key="'company-info-garden-info-totals-' + index">
                <td colspan="3" class="text-right">{{$t('globalTrans.total')}}</td>
                <td class="text-center" v-for="(total_info, totalInfoIndex) in sale_center.total_info" :key="'company-info-' + 'garden-info-' + totalInfoIndex + 'badc-fertilizer-info'">{{ $n(total_info.total_qty) }}</td>
              </tr>
            </tbody>
          </table>
      </template>
    </body-card>
    </slot>
    <!-- bcic sale center fertilizer total summery-->
    <slot v-for="(sale_center_parent, badcFertilizerIndex) in sales_center_arr" >
    <body-card :key="'center-total-info-' + badcFertilizerIndex" v-if="sale_center_parent.type === 2">
      <b-row class="my-3">
        <div class="d-flex justify-content-center">
          <h5 class="text-center">
            <p>{{ currentLocale === 'bn' ? getFiscalYearName(search.fiscal_year_id) + ' অর্থ বছরের বাগান ভিত্তিক ইউরিয়া সারের  ক্রমপুঞ্জিত পরিমাণ' : 'Garden wise cumulative quantity of Urea for the fiscal year ' + getFiscalYearName(search.fiscal_year_id) }} </p>
          </h5>
        </div>
      </b-row>
      <table class="table table-sm table-bordered section-tree-view-table">
        <thead>
          <tr>
            <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
            <th class="text-center">{{$t('teaGardenService.bcicSaleCenterName')}}</th>
            <th class="text-center">{{$t('teaGardenService.urea')}} {{$t('globalTrans.m_ton')}}</th>
          </tr>
        </thead>
          <tbody>
            <slot v-for="(sale_center, badcFertilizerIndex) in sale_center_parent.sales_center_info">
              <tr :key="'badc-total-info-' + badcFertilizerIndex">
                <td class="text-center">{{ $n(badcFertilizerIndex + 1) }}</td>
                <td class="text-center">{{getSaleCenterInfo(sale_center.sales_center_id)}}</td>
                <td class="text-center" v-for="(fertilizer, fertilizerIndex) in sale_center.fertilizer_info" :key="'badc-fertilizer-total-' + badcFertilizerIndex + '-' + fertilizerIndex">{{ $n(fertilizer.verified_qty) }}</td>
              </tr>
            </slot>
          </tbody>
        <tbody>
          <tr>
            <td colspan="2" class="text-right">{{$t('globalTrans.total')}}</td>
            <td v-for="(total_item, fertilizerTotalInfoIndex) in sale_center_parent.total_info" :key="'total-info-' +fertilizerTotalInfoIndex" class="text-center">{{$n(total_item.total_qty)}}</td>
          </tr>
        </tbody>
      </table>
    </body-card>
    </slot>
    <b-col md="12" class="text-left">
      <b-button @click="back"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
    </b-col>
    </b-overlay>
  </div>
</template>
<style scoped>
table#table-transition-example .flip-list-move {
  transition: transform 1s;
}
</style>
<script>

import
  RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { fertilizerRequestReportList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'
export default {
  mixins: [ModalBaseMasterList],
  data () {
    return {
      sortBy: '',
      sortDirection: 'desc',
      sortDesc: true,
      labelData: [],
      gardenList: [],
      editItemId: 0,
      today: new Date().toISOString().split('T')[0],
      item: {
        id: 0
      },
      data: [
        {
          company_info: [
            {
              garden_info: [
                {
                  fertilizer_info: []
                }
              ]
            }
          ],
          total_info: []
        }
      ],
      sales_center_arr: [
        {
          type: 1,
          sales_center_info: [{ fertilizer_info: [] }]
        },
        {
          type: 2,
          sales_center_info: [{ fertilizer_info: [] }]
        }
      ],
      badc_data_arr: [],
      bcic_data_arr: [],
      searchHeaderData: {
        circular_title_en: '',
        circular_title_bn: '',
        fiscal_year_en: '',
        fiscal_year_bn: ''
      },
      search: {
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        circular_id: this.$route.params.circular_id
      },
      showData: false
    }
  },
  created () {
    this.labelData = this.labelList
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions.map(item => {
        const customItem = {
          text: this.currentLocale === 'en' ? item.text : item.text_bn
        }
        return Object.assign({}, item, customItem)
      })
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    circularList () {
        return this.$store.state.TeaGardenService.commonObj.btbFertilizerCircularList.filter(item => item.status === 1)
    }
  },
  methods: {
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.appId }, this.search)
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 19)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, fertilizerRequestReportList + '/' + this.$route.params.circular_id, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    back () {
      return this.$router.go(-1)
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      if (this.search.fiscal_year_id) {
        const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(data => data.value === this.search.fiscal_year_id)
        this.searchHeaderData.fiscal_year_en = obj !== undefined ? obj.text_en : ''
        this.searchHeaderData.fiscal_year_bn = obj !== undefined ? obj.text_bn : ''
      }
      if (this.search.circular_id) {
        const obj = this.$store.state.TeaGardenService.commonObj.btbFertilizerCircularList.find(data => data.value === parseInt(this.search.circular_id))
        this.searchHeaderData.circular_title_en = obj !== undefined ? obj.text_en : ''
        this.searchHeaderData.circular_title_bn = obj !== undefined ? obj.text_bn : ''
      }
      this.showData = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, fertilizerRequestReportList + '/' + this.$route.params.circular_id, this.search)
      this.sales_center_arr = result.sales_center_arr
      this.data = result.data
      this.badc_data_arr = []
      this.bcic_data_arr = []
      result.data.forEach(item => {
        if (this.checkBadc(item.sales_center_id)) {
          this.badc_data_arr.push(item)
        } else {
          this.bcic_data_arr.push(item)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getFertilizerName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getSaleCenterInfo (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerDistributionList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en + ',' + obj.distrib_center_address_en : obj.text_bn + ',' + obj.distrib_center_address_bn
      }
    },
    getCompanyInfo (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaCompanyList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en + ',' + obj.address_en : obj.text_bn + ',' + obj.address_bn
      }
    },
    checkBadc (saleCenterId) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerDistributionList.find(item => item.value === saleCenterId)
      if (obj) {
        if (obj.sales_center === 1) {
          return true
        } else {
          return false
        }
      }
    }
  }
}
</script>
<style>
.blinking {
  animation: 1s blink ease infinite;
}

@keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
</style>
